@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400&display=swap);
:root {
    // colors
    --background: #ffffff;
    --backgroundDarker: rgb(243, 243, 243);
    --generalFont: #3f3f3f;
    --linkFont: #666666;
    --linkFontHover: #5522e2;
    --lightboxBackground1: #ffffff28;
    --lightboxBackground2: #ffffff8e;
    --lightboxBackground3: #ffffffbb;
    --scrollBarEle: rgb(196, 196, 196);
    // colorise
    --dataVisColor1: rgb(74, 141, 241);
    --dataVisColor2: rgb(255, 82, 38);
    --dataVisColor3: rgb(103, 233, 209);
    --dataVisColor4: rgb(159, 215, 255);
    //constant
    --constDark: #222222;
    --constLight: #eeeeee;
    // timing
    --backgroundTransitionTime: 1.5s;
}

.darkmode {
    // colors
    --background: #1b1b1b;
    --backgroundDarker: rgb(49, 49, 49);
    --generalFont: #e6e6e6;
    --linkFont: #c5c5c5;
    --linkFontHover: #5522e2;
    --lightboxBackground1: #00000028;
    --lightboxBackground2: #0000008e;
    --lightboxBackground3: #000000bb;
    --scrollBarEle: rgb(104, 104, 104);
    // colorise
    --dataVisColor1: rgb(74, 141, 241);
    --dataVisColor2: rgb(255, 82, 38);
    --dataVisColor3: rgb(103, 233, 209);
    --dataVisColor4: rgb(159, 215, 255);
    //constant
    --constDark: #222222;
    --constLight: #eeeeee;
}

//screen size
$screenBreak: 980px;