html {
    font-family: 'Raleway';
    background-color: var(--backgroundDarker);
    overflow: auto;
    scroll-padding-top: 6em;
    scroll-behavior: smooth;
    transition: background-color var(--backgroundTransitionTime) ease;
    ;
}

h1 {
    transition: color 0.5s ease;
    color: var(--generalFont);
}

h2 {
    transition: color 0.5s ease;
    color: var(--generalFont);
}

h3 {
    transition: color 0.5s ease;
    color: var(--generalFont);
}

h4 {
    transition: color 0.5s ease;
    color: var(--generalFont);
}

p {
    transition: color 0.5s ease;
    color: var(--generalFont);
}

ul {
    color: var(--generalFont);
}

body {
    margin: 0;
}

body::-webkit-scrollbar {
    transition: color 0.5s ease;
    width: 0.4em;
}

body::-webkit-scrollbar-track {
    transition: color 0.5s ease, background-color var(--backgroundTransitionTime) ease;
    background-color: var(--backgroundDarker);
}

body::-webkit-scrollbar-thumb {
    transition: color 0.5s ease, background-color var(--backgroundTransitionTime) ease;
    background-color: var(--scrollBarEle);
    border-radius: 10px;
}